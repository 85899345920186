<template>
  <main class="form page quiz-end">
    <section class="mainContent">
      <sectionHeader/>
      <!-- <div class="mobile-img">
        <img :src="details.image_formulaire"/>
      </div> -->
      <h1>Quiz terminé</h1>
      <h2 v-html="details.email_title"></h2>
      <h4 v-html="details.email_subheading"></h4>
      <emailForm :submitLabel="details.submit_label" />
    </section>
    <sideImage :imgSrc="details.image_formulaire"/>
  </main>
</template>

<script>
import sectionHeader from '@/components/sectionHeader.vue'
import sideImage from '@/components/sideImage.vue'
import emailForm from '@/components/emailForm.vue'

export default {
  components: {
    sectionHeader,
    emailForm,
    sideImage
  },
  method: {
  },
  computed: {
    details () {
      return this.$store.state.details
    },
    jobs () {
      return this.$store.state.jobs
    },
    questions () {
      return this.$store.state.questions
    }
  },
  created () {
    if (this.$store.state.results.length <= this.$store.state.questions.length) {
      console.log('doing something:' + this.$store.state.questions.length)
      this.$store.dispatch('theResults')
    }
    // this.$store.dispatch('theResults')
    if (this.$store.state.results.length === 0) {
      this.$router.push('results')
    }
  }
}
</script>

<style lang="scss">
  .quiz-end {

    .section-header {
      margin-bottom: 0;
    }

    .mobile-img {
      display: none;
    }

    h1 {
      margin-top: 30px;
      color: #59F87F;
      text-transform: uppercase;
      font-weight: 400;
      font-size: 50px;

      @media all and (max-width: 540px) {
        font-size: 30px;
      }
    }
    h2 {
      font-size: 50px;

      @media all and (max-width: 540px) {
        font-size: 25px;
      }
    }

    h4 {
      margin-top: 80px;
      font-size: 35px;
      font-family: 'Titillium Web', sans-serif;
      font-weight: 300;

      @media all and (max-width: 540px) {
        font-size: 18px;
      }
    }
  }
</style>
